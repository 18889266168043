<template>
    <div class="subscrib-content">
        <div class="cont-header">
           <span :selected="vipType==2" @click="onClickAuth(2)">个人认证</span>
           <span :selected="vipType==1" @click="onClickAuth(1)">企业认证</span>
        </div>
        <div class="cont" v-if="vipType==2">
            <div v-if="LoadpersonalData" style="margin-top:100px;">
                <p>申请授权认证</p>
                <p>完成认证后即可下载高清音乐，认证信息将用于生成电子版授权书</p>
                <div v-if="vipType==2">
                    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit" :colon="false" labelAlign="right">
                        <a-form-item label="姓名">
                            <a-input 
                                placeholder="请输入真实姓名"
                                v-decorator="['name', { rules: [{ required: true, message: '请输入真实姓名!' }] }]"
                            />
                        </a-form-item>
                        <a-form-item label="联系方式">
                            <a-input
                                placeholder="请输入手机号"
                                v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]"
                            />
                        </a-form-item>
                        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                            <a-button type="primary" html-type="submit" style="width: 156px;height:40px;">
                                确认提交
                            </a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div v-else>
                <a-row style="margin-top:100px;">
                    <a-col :span="24" style="font-size: 16px;">
                        <img src="@/assets/images/web/introduce/auth_success.svg" />
                         <span>  认证成功</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" style="margin-top:18px;font-size: 16px;">
                         <span>您已完成授权认证，认证信息如下：</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:18px;line-height:30px;">
                    <a-col :span="8" style="text-align:right;">
                        <span>真实姓名: </span>
                    </a-col>
                     <a-col :span="16" style="text-align:left;">
                        <span style="border:1px solid #E0E0E0;background:#ECECEC;display:inline-block;width:70%;margin-left:2%;">&nbsp;&nbsp;&nbsp;{{personalData.name}}</span>
                    </a-col>
                </a-row>
                 <a-row style="margin-top:9px;line-height:30px;">
                    <a-col :span="8" style="text-align:right;">
                        <span>联系方式: </span>
                    </a-col>
                     <a-col :span="16" style="text-align:left;">
                        <span style="border:1px solid #E0E0E0;background:#ECECEC;display:inline-block;width:70%;margin-left:2%;">&nbsp;&nbsp;&nbsp;{{personalData.phone}}</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" style="margin-top:18px;font-size: 14px;">
                        <span style="color:red;">*</span><span> 认证信息将用于生成电子版授权书，不支持自助修改，如有疑问请联系客服</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:40px;">
                    <a-col :span="24">
                    <router-link :to="`/allmusic`"><span style="text-align:center;background: #FF7B13;border-radius: 2px;font-weight: 500;color: #FFFFFF;padding:10px 57px;font-size:14px;">去下载</span></router-link>
                </a-col>
                </a-row>
            </div>
        </div>

        <div class="cont" v-if="vipType==1">
            <div v-if="LoadcompanyData" style="margin-top:100px;">
                <p>申请授权认证</p>
                <p>完成认证后即可下载高清音乐，认证信息将用于生成电子版授权书</p>
                <div>
                    <a-form :form="form_company" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" @submit="handleSubmitCompany" :colon="false" labelAlign="right">
                        <a-form-item label="公司名">
                            <a-input 
                                placeholder="请输入企业全称"
                                v-decorator="['name', { rules: [{ required: true, message: '请输入企业全称!' }] }]"
                            />
                        </a-form-item>
                        <a-form-item label="税号">
                            <a-input
                                placeholder="纳税人唯一识别号"
                                v-decorator="['due', { rules: [{ required: true, message: '请输入纳税人唯一识别号!' }] }]"
                            />
                        </a-form-item>
                        <a-form-item label="联系方式">
                            <a-input
                                placeholder="请输入手机号"
                                v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]"
                            />
                        </a-form-item>
                        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                            <a-button type="primary" html-type="submit" style="width: 156px;height:40px;">
                                确认提交
                            </a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div v-else>
                <a-row style="margin-top:100px;">
                    <a-col :span="24" style="font-size: 16px;">
                        <img src="@/assets/images/web/introduce/auth_success.svg" />
                         <span>  认证成功</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" style="margin-top:18px;font-size: 16px;">
                         <span>您已完成授权认证，认证信息如下：</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:18px;line-height:30px;">
                    <a-col :span="8" style="text-align:right;">
                        <span>公司名称: </span>
                    </a-col>
                     <a-col :span="16" style="text-align:left;">
                        <span style="border:1px solid #E0E0E0;background:#ECECEC;display:inline-block;width:70%;margin-left:2%;">&nbsp;&nbsp;&nbsp;{{companyData.name}}</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:9px;line-height:30px;">
                    <a-col :span="8" style="text-align:right;">
                        <span>税号: </span>
                    </a-col>
                     <a-col :span="16" style="text-align:left;">
                        <span style="border:1px solid #E0E0E0;background:#ECECEC;display:inline-block;width:70%;margin-left:2%;">&nbsp;&nbsp;&nbsp;{{companyData.due}}</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:9px;line-height:30px;">
                    <a-col :span="8" style="text-align:right;">
                        <span>联系方式: </span>
                    </a-col>
                     <a-col :span="16" style="text-align:left;">
                        <span style="border:1px solid #E0E0E0;background:#ECECEC;display:inline-block;width:70%;margin-left:2%;">&nbsp;&nbsp;&nbsp;{{companyData.phone}}</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" style="margin-top:18px;font-size: 14px;">
                        <span style="color:red;">*</span><span> 认证信息将用于生成电子版授权书，不支持自助修改，如有疑问请联系客服</span>
                    </a-col>
                </a-row>
                <a-row style="margin-top:40px;">
                    <a-col :span="24">
                    <router-link :to="`/allmusic`"><span style="text-align:center;background: #FF7B13;border-radius: 2px;font-weight: 500;color: #FFFFFF;padding:10px 57px;font-size:14px;">去下载</span></router-link>
                </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
      return {
          vipType: 2,
          form: this.$form.createForm(this, "auth-personal"),
          form_company: this.$form.createForm(this, "auth-company"),
          companyData:[],
          LoadcompanyData: false,
          personalData:[],
          LoadpersonalData: false,
      }
    },
    created() {
        this.$axios.vipAuthlist().then(res => {
            if (res.data.code == 0) {
                let auth_data = res.data.data.list;
                if(auth_data[2]) {
                    this.personalData = auth_data[2];
                } else {
                    this.LoadpersonalData = true;
                }
                if(auth_data[1]) {
                    this.companyData = auth_data[1];
                } else {
                    this.LoadcompanyData = true;
                }
            }
        })
    },
    methods: {
        onClickAuth(idx){
            this.vipType = idx;
        },
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    let params = {
                        'auth_type': 2,
                        'name': values.name,
                        'phone': values.phone
                    }
                    let that = this;
                    this.$axios.saveAuth(params).then(res => {
                        const data = res.data;
                        if (data && data.code == 0) {
                            that.LoadpersonalData = false;
                            that.personalData.name = data.data.name;
                            that.personalData.phone = data.data.phone;
                        }
                    }).catch(() => {
                        that.LoadpersonalData = true;
                    })
                }
            });
        },
        handleSubmitCompany(e){
            e.preventDefault();
            this.form_company.validateFields((err, values) => {
                if (!err) {
                    let params = {
                        'auth_type': 1,
                        'name': values.name,
                        'phone': values.phone,
                        'due': values.due
                    }
                    let that = this;
                    this.$axios.saveAuth(params).then(res => {
                        const data = res.data;
                        if (data && data.code == 0) {
                            that.LoadcompanyData = false;
                            that.companyData.name = data.data.name;
                            that.companyData.phone = data.data.phone;
                            that.companyData.due = data.data.due;
                        }
                    }).catch(() => {
                        that.LoadcompanyData = true;
                    })
                }
            });
        },
    },
}
</script>
<style lang="scss" scoped>
    .ant-input{
        border-radius: 2px;
        height: 40px;
    }
    .cont-header{
        height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            line-height: 66px;
            text-align:center;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-left: 10px;
        }
        span:nth-child(1){
            margin-left: 39px;
        }
        span:nth-child(2){
            margin-left: 45px;
        }
        span[selected]{
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
        span:hover{
            cursor: pointer;
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
    }
    .cont{
        width: 60%;
        margin: 0 auto;
        padding-top:41px;
        text-align:center;
        p:nth-child(1){
            height: 24px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }
        p:nth-child(2){
            height: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
    }
</style>